import React from "react";
import TinySlider from "tiny-slider-react";
import { Col, Container, Row } from "reactstrap";

import m from "../assets/images/client/m.png";
import f from "../assets/images/client/f.jpg";

import Map from "../assets/images/map.png";
import 'tiny-slider/dist/tiny-slider.css';
import { matchPath } from "react-router";

const settings = {
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 12,
    responsive: {
        992: {
            items: 3
        },

        767: {
            items: 2
        },

        320: {
            items: 1
        },
    },
};

export default function Review() {
    const review = [
        {
            id: '1',
            profile: m,
            name: 'Jane Smith',
            designation: "Manager",
            description: "We are extremely satisfied with the services provided by SKPTYLTD IT Solutions. Sumira Khan demonstrated remarkable expertise and commitment to ensuring all our IT needs were met with precision and efficiency."
        },
        {
            id: '2',
            profile: m,
            name: 'Mike Anderson',
            designation: "CEO",
            description: "The team at SKPTYLTD IT Solutions, led by Sumaira Khan, provided us with unparalleled IT support and services. Their responsive and proactive approach helped us enhance our operational efficiency significantly."
        }, {
            id: '3',
            profile: f,
            name: 'Sarah Wilson',
            designation: "Marketing Head",
            description: "Our collaboration with SKPTYLTD IT Solutions has been invaluable. Sumaira Khan and her team have been instrumental in the seamless integration of new IT systems within our organisation, enabling us to optimise our processes and focus on our core business."
        }, {
            id: '4',
            profile: m,
            name: 'Chris Martin',
            designation: "Business Operation",
            description: "SKPTYLTD IT Solutions delivered exceptional service and innovative solutions that were tailored to our unique needs. Sumaira Khan's leadership and in-depth knowledge were pivotal in addressing our IT challenges and transforming our business."
        }
    ]

    return (
        <>
            {/* Review Start  */}
            <section className="section" id="review">
                <Container>
                    <Row className="justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center mb-4 pb-2">
                                <h4 className="title mb-3">Happy Client's</h4>
                                <p className="text-muted para-desc mx-auto mb-0">Some sweet words from our Happy clients.</p>
                            </div>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-12 mt-4">
                            <div className="tiny-three-item">
                                <TinySlider settings={settings} className="tiny-slide">
                                    {review.map((el, index) => (
                                        <div className="customer-testi m-1" key={index}>
                                            <div className="content p-3 shadow rounded bg-white position-relative">
                                                <ul className="list-unstyled mb-0 text-warning">
                                                    <li className="list-inline-item"><i className="mdi mdi-star"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star"></i></li>
                                                </ul>
                                                <p className="text-muted mt-2">{el.description}</p>
                                            </div>
                                            <div className="text-center mt-3">
                                                <img src={el.profile} className="avatar avatar-small rounded shadow" alt="" />
                                                <p className="text-primary mt-3 mb-0">{el.name} <small className="text-muted d-block ms-2">{el.designation}</small></p>
                                            </div>
                                        </div>
                                    ))}
                                </TinySlider>
                            </div>
                        </div>
                    </Row>
                </Container>

                <Container className="mt-100 mt-60">
                    <div className="py-5 px-4 bg-soft-primary rounded-lg" style={{
                        background: `url('${Map}') center center`
                    }}>
                        <Row className="justify-content-center">
                            <div className="col-12">
                                <div className="section-title text-center mb-4 pb-2">
                                    <h4 className="title mb-4">Subscribe our Newsletter</h4>
                                    <p className="text-muted mx-auto para-desc mb-0">Subscribe now to stay informed, entertained, and connected! Join our community and gain access to exclusive content, special offers, and the latest updates.</p>
                                </div>
                            </div>

                            <Col lg={8}>
                                <div className="text-center subcribe-form mt-4 pt-2">
                                    <form>
                                        <div className="form-group mb-0">
                                            <input type="email" id="email2" name="email" className="rounded-pill" placeholder="Your Email Id" />
                                            <button type="submit" className="btn btn-pills btn-primary">Subscribe Now</button>
                                        </div>
                                    </form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>

            </section>
        </>
    )
}
