import React, { useState } from 'react';
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

export default function Contact() { 
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [comments, setComments] = useState("");

    //   Form validation state
    const [errors, setErrors] = useState({});

    //   Setting button text on form submission
    const [buttonText, setButtonText] = useState("Send");
    const [resturnMsg, setresturnMsg] = useState("");
    // Setting success or failure messages states
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showFailureMessage, setShowFailureMessage] = useState(false);

    const handleValidation = () => {
        let tempErrors = {};
        let isValid = true;
    
        if (name.length <= 0) {
          tempErrors["name"] = true;
          isValid = false;
        }
        if (email.length <= 0) {
          tempErrors["email"] = true;
          isValid = false;
        }
        if (subject.length <= 0) {
          tempErrors["subject"] = true;
          isValid = false;
        }
        if (comments.length <= 0) {
          tempErrors["comments"] = true;
          isValid = false;
        }
    
        setErrors({ ...tempErrors });
        setShowSuccessMessage(false);
        setShowFailureMessage(false);
        //console.log("errors", errors);
        return isValid;
      };
    
      //   Handling form submit
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        let isValidForm = handleValidation();
    
        if (isValidForm) {
          setButtonText("Sending");
          await fetch("https://demo.xpansion.com.au/api/test/contact.php", {
            body: JSON.stringify({
              email: email,
              name: name,
              subject: subject,
              comments: comments,
            }),
            method: "POST",
          }).then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((result) => {
            if (result.success) {
                setShowSuccessMessage(true);
                setShowFailureMessage(false);
                setName('');
                setEmail('');
                setSubject('');
                setComments('');
            } else {
                setShowSuccessMessage(false);
                setShowFailureMessage(true);                
            }
            setresturnMsg(result.message);
            setButtonText("Send");
          });
        }
        //console.log(name, email, subject, comments);
      };

    return (
        <>
            {/* Start Contact  */}
            <section className="section" id="contact">
                <Container>
                    <Row className="justify-content-center">
                        <Col>
                            <div className="section-title text-center mb-4 pb-2">
                                <h4 className="title mb-3">Get In Touch !</h4>
                                <p className="text-muted para-desc mb-0 mx-auto">Do you have any questions or suggestion, feel free to mail us.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="align-items-center">
                        <Col lg={8} md={6} className="order-md-2 order-1 mt-4 pt-2">
                            <div className="p-4 rounded shadow bg-white">
                                <form method="post" name="myForm" onSubmit={handleSubmit} >
                                    <p className="mb-0" id="error-msg"></p>
                                    <div id="simple-msg"></div>
                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-4">
                                                <input name="name" id="name" type="text" className="form-control" placeholder="Name :" value={name} onChange={(e) => { setName(e.target.value); }} />
                                                {errors?.name && (
                                                    <p className="text-red-500">Please Enter Your Name.</p>
                                                )}
                                            </div>
                                        </Col>

                                        <Col md={6} >
                                            <div className="mb-4">
                                                <input name="email" id="email" type="email" className="form-control" placeholder="Email :"value={email} onChange={(e) => { setEmail(e.target.value); }}  />
                                                {errors?.email && (
                                                    <p className="text-red-500">Please Enter Valid Email.</p>
                                                )}
                                            </div>
                                        </Col>

                                        <div className="col-12">
                                            <div className="mb-4">
                                                <input name="subject" id="subject" className="form-control" placeholder="Subject :" value={subject} onChange={(e) => { setSubject(e.target.value); }}  />
                                                {errors?.subject && (
                                                    <p className="text-red-500">Please Enter Subject Line.</p>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="mb-4">
                                                <textarea name="comments" id="comments" rows={4} className="form-control" placeholder="Message :" value={comments} onChange={(e) => { setComments(e.target.value); }} ></textarea>
                                                {errors?.comments && (
                                                    <p className="text-red-500">Please Enter Your Message.</p>
                                                )}
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-12 text-end">
                                        <div className="text-left">
                                            {showSuccessMessage && (
                                            <p className="text-green-500 font-semibold text-sm my-2">
                                                {resturnMsg}
                                            </p>
                                            )}
                                            {showFailureMessage && (
                                            <p className="text-red-500">
                                                {resturnMsg}
                                            </p>
                                            )}
                                        </div>
                                            <button type="submit" id="submit" name="send" className="btn btn-primary">{buttonText}</button>
                                        </div>
                                    </Row>
                                </form>
                            </div>
                        </Col>

                        <Col lg={4} md={6} className="col-12 order-md-1 order-2 mt-4 pt-2">
                            <div className="me-lg-4">
                                <div className="d-flex">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-phone d-block rounded h4 mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-3">
                                        <h5 className="mb-2">Phone</h5>
                                        <Link to="tel:+61401495203" className="text-muted">+61401495203</Link>
                                    </div>
                                </div>

                                <div className="d-flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-envelope d-block rounded h4 mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-3">
                                        <h5 className="mb-2">Email</h5>
                                        <Link to="mailto:admin@skptyltd.com" className="text-muted">admin@skptyltd.com</Link>
                                    </div>
                                </div>

                                <div className="d-flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-map-marker d-block rounded h4 mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-3">
                                        <h5 className="mb-2">Location</h5>
                                        <p className="text-muted mb-2">suite 8, 46-48 Restwell Street Bankstown NSW 2200</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* End Contact  */}
        </>
    )
}