import React, { useState, useEffect } from 'react';
import {
    Col, Container, Row, Nav,
    NavbarBrand,
    NavbarToggler,
    Collapse,
    NavItem
} from "reactstrap";
import * as Icon from 'react-feather';
// import Typed from 'react-typed';
import Typed from "typed.js";
import { Link } from 'react-scroll';
import { Link as Link2 } from 'react-router-dom';

// Modal Video
import Logodark from "../../assets/images/logo-dark.png";
import Logolight from "../../assets/images/logo-light.png";
import About from "../../assets/images/personal/about.jpg";
import Hero from "../../assets/images/personal/hero.png";
import Review from "../../component/Review";
import Contact from "../../component/Contact";
import Cta2 from "../../assets/images/personal/cta-2.jpg";
import PortfolioSection from "../../component/Portfolio";

export default function Personal() {
    // const [modal, setModal] = useState(false);
    // const [isOpen, setIsOpen] = useState(false);
    // const [toggleMenu, setToggleMenu] = useState(false);
    const [arrow, setArrow] = useState(false);
    const [isOpen, setMenu] = useState(true);

    const handleScroll = () => {
        if (
            document.body.scrollTop >= 500 ||
            document.documentElement.scrollTop >= 500
        ) {
            setArrow(true);
        } else {
            setArrow(false);
        }
    };

    const toggleMenu = () => {
        setMenu(!isOpen)
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);


        const typed = new Typed('#typed', {
            strings: [
                "<b>SKPTY Ltd</b>",
                "<b>Photographer</b>",
                "<b>Web Designer</b>",
                "<b>UX / UI Designer</b>",
            ],
            backDelay: 2000,
            loop: true,
            startDelay: 300,
            typeSpeed: 100,
            backSpeed: 100,
        });

        return () => {
            window.removeEventListener('scroll', handleScroll);
            typed.destroy();
        };
    }, []);

    return (
        <>
            <nav id="navbar" className="navbar navbar-expand-lg fixed-top sticky bg-white">
                <Container>
                    <NavbarBrand className="navbar-brand" href="/">
                        <img src={Logodark} className="logo-light-mode" alt="" />
                        <img src={Logolight} className="logo-dark-mode" alt="" />
                    </NavbarBrand>
                    <NavbarToggler onClick={toggleMenu}>
                        <Icon.Menu />
                    </NavbarToggler>

                    <Collapse className={`navbar-collapse ${isOpen === true ? 'hidden' : 'show'}`} id="navbarSupportedContent">
                        <Nav className="navbar-nav ms-auto mb-2 mb-lg-0" id="navbar-navlist">
                            <NavItem>
                                <Link activeClass="active" spy={true} smooth={true} duration={500} className="nav-link" to="home">Home</Link>
                            </NavItem>
                            <NavItem>
                                <Link activeClass="active" spy={true} smooth={true} duration={500} className="nav-link" to="about">About Me</Link>
                            </NavItem>
                            <NavItem>
                                <Link activeClass="active" spy={true} smooth={true} duration={500} className="nav-link" to="service">Services</Link>
                            </NavItem>
                            <NavItem>
                                <Link activeClass="active" spy={true} smooth={true} duration={500} className="nav-link" to="portfolio">Portfolio</Link>
                            </NavItem>
                            <NavItem>
                                <Link activeClass="active" spy={true} smooth={true} duration={500} className="nav-link" to="contact">Contact Us</Link>
                            </NavItem>
                        </Nav>

                        <ul className="list-inline menu-social mb-0 ps-lg-4 ms-2">
                            <li className="list-inline-item">
                                <a href="tel:+61401495203" className="text-primary d-flex align-items-center"><span className="btn btn-sm btn-icon btn-pills btn-primary me-2"><Icon.Phone className="icons" /></span> Call Me</a>
                            </li>
                        </ul>
                    </Collapse>
                </Container>
            </nav>
            {/* HOME START */}
            <section className="bg-half-170 d-table w-100 bg-light bg-animation-right pb-0 overflow-hidden" id="home">
                <Container>
                    <Row className="align-items-center position-relative" style={{ zIndex: 1 }}>
                        <Col lg={9} >
                            <div className="position-absolute text-mover top-0">
                                SKPTY Ltd.
                            </div>
                        </Col>
                        <Col lg={7} md={6}>

                            <div className="title-heading">
                                <h4 className="display-4 fw-bold">Hello !</h4>
                                <h5 className="heading sub-heading mb-3">I am {" "}
                                    <span id="typed" className="typewrite position-relative text-type-element"></span>
                                </h5>
                                <p className="text-muted para-desc mb-0">We are working since 2018 as solution provider for It and It enabled services. We are best in services like Web Design and Development, Application development.</p>

                                <div className="mt-4">
                                    <Link to="contact" spy={true} smooth={true} duration={500} className="btn btn-primary">Discuss Project</Link>
                                </div>
                            </div>
                        </Col>

                        <Col lg={5} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div className="position-relative">
                                <img src={Hero} className="img-fluid" alt="" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* HOME END */}

            <section className="section mt-5" id="about">
                <Container>
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-6">
                            <img src={About} className="rounded shadow img-fluid" alt="" />
                        </div>

                        <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="ms-lg-5">
                                <div className="section-title">
                                    <h4 className="title mb-3">About Skpty Ltd.</h4>
                                    <p className="text-muted">Well known name in It industry since 2018. We provide It solution for Agencies, SME's and startup business.</p>
                                    <p className="text-muted mb-0">In last 5 years we server for many clients in Real Estate, Hospital, Education, Supply Chain industries. We provide quality solution inculding graphics design to application development.</p>
                                    <p className="text-muted mb-0">We use the latest tachnologies including HTML5, Css, Laravel, PHP, WordPress, Joomla, Oprncart, shopify, MySql.</p>
                                </div>

                                <div className="progress-box mt-4">
                                    <h6 className="title fw-normal text-muted">WordPress</h6>
                                    <div className="progress">
                                        <div className="progress-bar position-relative bg-primary" style={{ width: '84%' }}>
                                            <div className="progress-value d-block text-muted h6">84%</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="progress-box mt-4">
                                    <h6 className="title fw-normal text-muted">Angular / JavaScript</h6>
                                    <div className="progress">
                                        <div className="progress-bar position-relative bg-primary" style={{ width: '79%' }}>
                                            <div className="progress-value d-block text-muted h6">79%</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="progress-box mt-4">
                                    <h6 className="title fw-normal text-muted">HTML</h6>
                                    <div className="progress">
                                        <div className="progress-bar position-relative bg-primary" style={{ width: '95%' }}>
                                            <div className="progress-value d-block text-muted h6">95%</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-4 pt-2">
                                    <Link spy={true} smooth={true} duration={500} to="portfolio" className="btn btn-soft-primary">View my Projects</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

            {/* Start service  */}
            <section className="section bg-light" id="service">
                <Container>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center mb-4 pb-2">
                                <h4 className="title mb-3">What Do I Offer ?</h4>
                                <p className="para-desc text-muted mx-auto mb-0">Obviously I'm a Web Designer. Experienced with all stages of the development cycle for dynamic web projects.</p>
                            </div>
                        </div>
                    </div>

                    <Row>
                        <Col lg={4} md={6} className="mt-4 pt-2">
                            <div className="card features feature-primary feature-clean feature-transition p-4 py-5 border-0 shadow rounded-md overflow-hidden">
                                <Icon.Airplay className="fea icon-lg fea-icon" />
                                <div className="content mt-4">
                                    <h5>UX / UI Design</h5>
                                    <p className="text-muted mt-3">Designing a wearable user experience, for young users taking their first steps to digital.</p>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={6} className="mt-4 pt-2">
                            <div className="card features feature-primary feature-clean feature-transition p-4 py-5 border-0 shadow rounded-md overflow-hidden">
                                <Icon.Aperture className="fea icon-lg fea-icon" />
                                <div className="content mt-4">
                                    <h5>Ios App Designer</h5>
                                    <p className="text-muted mt-3">We follow latest UX trends to design and develop IOS app. We have 5 years of experince in this field.</p>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={6} className="mt-4 pt-2">
                            <div className="card features feature-primary feature-clean feature-transition p-4 py-5 border-0 shadow rounded-md overflow-hidden">
                                <Icon.Camera className="fea icon-lg fea-icon" />
                                <div className="content mt-4">
                                    <h5>Web Development</h5>
                                    <p className="text-muted mt-3">We design responsive, scalable and modern look design for agency, SME's and startups all over the globe.</p>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={6} className="mt-4 pt-2">
                            <div className="card features feature-primary feature-clean feature-transition p-4 py-5 border-0 shadow rounded-md overflow-hidden">
                                <Icon.Compass className="fea icon-lg fea-icon" />
                                <div className="content mt-4">
                                    <h5>Graphic Designer</h5>
                                    <p className="text-muted mt-3">We proovide the best solution for logo, flyer, emailer, brochure and business card deisgn.</p>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={6} className="mt-4 pt-2">
                            <div className="card features feature-primary feature-clean feature-transition p-4 py-5 border-0 shadow rounded-md overflow-hidden">
                                <Icon.Settings className="fea icon-lg fea-icon" />
                                <div className="content mt-4">
                                    <h5>Web Security</h5>
                                    <p className="text-muted mt-3">We help in cyber threats and cyberattacks on web-based applications has considerably increased in the recent past.</p>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={6} className="mt-4 pt-2">
                            <div className="card features feature-primary feature-clean feature-transition p-4 py-5 border-0 shadow rounded-md overflow-hidden">
                                <Icon.MessageCircle className="fea icon-lg fea-icon" />
                                <div className="content mt-4">
                                    <h5>24/7 Support</h5>
                                    <p className="text-muted mt-3">We are for your needs, no matter what time of day via Slack, Zoom, SMS, Phone, Email, or our project management portal.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <PortfolioSection />
            <Review />
            <section className="section" style={{ background: `url(${Cta2}) top` }}>
                <div className="bg-overlay"></div>
                <Container>
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <h4 className="text-light title-dark">We are ready to accept Projects.</h4>
                            <p className="text-white-50 mx-auto mt-4 para-desc">Do you have a creative idea, a business challenge, or a unique project in mind, SKPTYLTD is here to bring your vision to life.</p>
                            <div className="mt-4">
                                <Link to="contact" spy={true} smooth={true} duration={500} className="btn btn-primary mouse-down">Discuss Project <Icon.ChevronDown className="fea icon-sm" /> </Link>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <Contact />
            <footer className="bg-footer footer-bar">
                <div className="footer-py-30">
                    <div className="container text-center">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-sm-12">
                                <div className="text-sm-start">
                                    <p className="mb-0">© {2018}{" "} SKPTYLTD. Developed By <Link2 to="https://xpansion.com.au/" target='_blank' className="text-reset">Xpansion Technologies</Link2>.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </footer>
        </>
    )
};